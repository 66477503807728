import * as React from "react";

import {
  Edit,
  SimpleForm,
  EditProps,
  DateInput,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
  NumberInput,
  BooleanInput,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

import { InvoiceTitle } from "../invoice/InvoiceTitle";
import { SupplierTitle } from "../supplier/SupplierTitle";
import { UserTitle } from "../user/UserTitle";

export const ContractEdit = (props: EditProps): React.ReactElement => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <DateInput label="Date of issue" source="dateIssue" />
        <DateInput label="Delivery date" source="deliveryDate" />
        <ReferenceArrayInput
          source="invoices"
          reference="Invoice"
          parse={(value: any) => value && value.map((v: any) => ({ id: v }))}
          format={(value: any) => value && value.map((v: any) => v.id)}
        >
          <SelectArrayInput optionText={InvoiceTitle} />
        </ReferenceArrayInput>
        <TextInput label="Number of Contract" source="numberOfContract" />
        <NumberInput label="Price" source="price" />
        <BooleanInput label="Price with DPH" source="priceWithDph" />
        <DateTimeInput label="Public Date" source="publicDate" />
        <TextInput label="Subject of the order" source="subject" />
        <ReferenceInput
          source="supplier.id"
          reference="Supplier"
          label="Supplier"
        >
          <SelectInput optionText={SupplierTitle} />
        </ReferenceInput>
        <ReferenceInput source="user.id" reference="User" label="User">
          <SelectInput optionText={UserTitle} />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
