import * as React from "react";

import {
  Show,
  SimpleShowLayout,
  ShowProps,
  DateField,
  TextField,
  ReferenceManyField,
  Datagrid,
  BooleanField,
  ReferenceField,
} from "react-admin";

import { SUPPLIER_TITLE_FIELD } from "../supplier/SupplierTitle";
import { USER_TITLE_FIELD } from "./UserTitle";

export const UserShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DateField source="createdAt" label="Created At" />
        <TextField label="Email" source="email" />
        <TextField label="First Name" source="firstName" />
        <TextField label="ID" source="id" />
        <TextField label="Last Name" source="lastName" />
        <TextField label="Roles" source="roles" />
        <DateField source="updatedAt" label="Updated At" />
        <TextField label="Username" source="username" />
        <ReferenceManyField
          reference="Contract"
          target="UserId"
          label="Contracts"
        >
          <Datagrid rowClick="show">
            <DateField source="createdAt" label="Created At" />
            <TextField label="Date of issue" source="dateIssue" />
            <TextField label="Delivery date" source="deliveryDate" />
            <TextField label="ID" source="id" />
            <TextField label="Number of Contract" source="numberOfContract" />
            <TextField label="Price" source="price" />
            <BooleanField label="Price with DPH" source="priceWithDph" />
            <TextField label="Public Date" source="publicDate" />
            <TextField label="Subject of the order" source="subject" />
            <ReferenceField
              label="Supplier"
              source="supplier.id"
              reference="Supplier"
            >
              <TextField source={SUPPLIER_TITLE_FIELD} />
            </ReferenceField>
            <DateField source="updatedAt" label="Updated At" />
            <ReferenceField label="User" source="user.id" reference="User">
              <TextField source={USER_TITLE_FIELD} />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField reference="Order" target="UserId" label="Orders">
          <Datagrid rowClick="show">
            <DateField source="createdAt" label="Created At" />
            <TextField label="Date of issue" source="dateIssue" />
            <TextField label="Delivery date" source="deliveryDate" />
            <TextField label="ID" source="id" />
            <TextField label="Number of Order" source="numberOfOrder" />
            <TextField label="Price" source="price" />
            <BooleanField label="Price with DPH" source="priceWithDPH" />
            <TextField label="Public Date" source="publicDate" />
            <TextField label="Subject of the order" source="subject" />
            <ReferenceField
              label="Supplier"
              source="supplier.id"
              reference="Supplier"
            >
              <TextField source={SUPPLIER_TITLE_FIELD} />
            </ReferenceField>
            <DateField source="updatedAt" label="Updated At" />
            <ReferenceField label="User" source="user.id" reference="User">
              <TextField source={USER_TITLE_FIELD} />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
