import * as React from "react";

import {
  Show,
  SimpleShowLayout,
  ShowProps,
  DateField,
  TextField,
  BooleanField,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
} from "react-admin";

import { CONTRACT_TITLE_FIELD } from "../contract/ContractTitle";
import { ORDER_TITLE_FIELD } from "./OrderTitle";
import { SUPPLIER_TITLE_FIELD } from "../supplier/SupplierTitle";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const OrderShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DateField source="createdAt" label="Created At" />
        <TextField label="Date of issue" source="dateIssue" />
        <TextField label="Delivery date" source="deliveryDate" />
        <TextField label="ID" source="id" />
        <TextField label="Number of Order" source="numberOfOrder" />
        <TextField label="Price" source="price" />
        <BooleanField label="Price with DPH" source="priceWithDPH" />
        <TextField label="Public Date" source="publicDate" />
        <TextField label="Subject of the order" source="subject" />
        <ReferenceField
          label="Supplier"
          source="supplier.id"
          reference="Supplier"
        >
          <TextField source={SUPPLIER_TITLE_FIELD} />
        </ReferenceField>
        <DateField source="updatedAt" label="Updated At" />
        <ReferenceField label="User" source="user.id" reference="User">
          <TextField source={USER_TITLE_FIELD} />
        </ReferenceField>
        <ReferenceManyField
          reference="Invoice"
          target="OrderId"
          label="Invoices"
        >
          <Datagrid rowClick="show">
            <ReferenceField
              label="Contract"
              source="contract.id"
              reference="Contract"
            >
              <TextField source={CONTRACT_TITLE_FIELD} />
            </ReferenceField>
            <DateField source="createdAt" label="Created At" />
            <BooleanField label="Dodavatelska" source="dodavatelska" />
            <TextField label="ID" source="id" />
            <TextField label="Number of Invoice" source="numberOfInvoice" />
            <ReferenceField label="Order" source="order.id" reference="Order">
              <TextField source={ORDER_TITLE_FIELD} />
            </ReferenceField>
            <TextField label="Public Date" source="publicDate" />
            <TextField label="Subject" source="subject" />
            <DateField source="updatedAt" label="Updated At" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
